/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseResponse } from './CaseResponse';
import {
    CaseResponseFromJSON,
    CaseResponseFromJSONTyped,
    CaseResponseToJSON,
} from './CaseResponse';
import type { TextEntryResponse } from './TextEntryResponse';
import {
    TextEntryResponseFromJSON,
    TextEntryResponseFromJSONTyped,
    TextEntryResponseToJSON,
} from './TextEntryResponse';

/**
 * 
 * @export
 * @interface TextCollectionResponse
 */
export interface TextCollectionResponse {
    /**
     * 
     * @type {Date}
     * @memberof TextCollectionResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof TextCollectionResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof TextCollectionResponse
     */
    id: string;
    /**
     * 
     * @type {CaseResponse}
     * @memberof TextCollectionResponse
     */
    _case: CaseResponse;
    /**
     * 
     * @type {Array<TextEntryResponse>}
     * @memberof TextCollectionResponse
     */
    entries: Array<TextEntryResponse>;
}

/**
 * Check if a given object implements the TextCollectionResponse interface.
 */
export function instanceOfTextCollectionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "_case" in value;
    isInstance = isInstance && "entries" in value;

    return isInstance;
}

export function TextCollectionResponseFromJSON(json: any): TextCollectionResponse {
    return TextCollectionResponseFromJSONTyped(json, false);
}

export function TextCollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextCollectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        '_case': CaseResponseFromJSON(json['case']),
        'entries': ((json['entries'] as Array<any>).map(TextEntryResponseFromJSON)),
    };
}

export function TextCollectionResponseToJSON(value?: TextCollectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'case': CaseResponseToJSON(value._case),
        'entries': ((value.entries as Array<any>).map(TextEntryResponseToJSON)),
    };
}

