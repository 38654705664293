import { ColumnDef } from '@tanstack/react-table';
import { CustomTable } from 'components/Table/CustomTable';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useEffect, useMemo, useState } from 'react';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import {
  EPermission,
  TextCollectionResponse,
  TextCollectionResponsePagedData,
} from 'api/core';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import {
  useDeleteTextCollection,
  useGetTextCollection,
} from 'api/useTextCollectionsApi';
import { SmartTextDetails } from './SmartTextDetails';
import { useLocation } from 'react-router-dom';

interface TableProps {
  data?: TextCollectionResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const SmartTextsTable = ({
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const location = useLocation();

  const [viewTarget, setViewTarget] = useState<TextCollectionResponse | null>(
    null
  );

  const [deleteTarget, setDeleteTarget] =
    useState<TextCollectionResponse | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const matchedTarget = data?.data.find((x) => x.id === id) ?? null;

    if (matchedTarget) {
      setViewTarget(matchedTarget);
    }

    // Remove ?id from the URL, but without causing a page reload, so the user can still use the back button
    if (id) {
      searchParams.delete('id');
      window.history.replaceState(
        null,
        '',
        `${location.pathname}${searchParams.toString() ? '?' : ''}${searchParams.toString()}`
      );
    }
  }, [location.search, location.pathname, data, setViewTarget]); // Depend on location.search, data, and setViewTarget

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<TextCollectionResponse>[] = [
      {
        accessorKey: '_view',
        header: 'Vis',
        enableSorting: false,
        cell: (e) => (
          <ActionCell
            icon={<AnimatedIcon icon="open-icon" className="h-6 w-6" />}
            onClick={() => setViewTarget(e.row.original)}
          />
        ),
      },
      {
        accessorKey: '_case.address',
        header: 'Adresse',
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              permissions={[EPermission.TextCollectionDelete]}
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {viewTarget ? (
        <SmartTextDetails
          id={viewTarget.id}
          isInitialOpen={true}
          onClosed={() => setViewTarget(null)}
        />
      ) : null}

      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={undefined}
          getter={useGetTextCollection}
          deleter={useDeleteTextCollection}
        />
      ) : null}
    </>
  );
};
